import React from 'react';
import Fallback from '../Fallback';

export default function IdeaComments({ __placeholder_html__, ...props }) {
  const IdeaComments = React.lazy(() =>
    import('../../components/ideas/IdeaComments')
  );
  return (
    <React.Suspense
      fallback={<Fallback __placeholder_html__={__placeholder_html__} />}
    >
      <IdeaComments {...props} />
    </React.Suspense>
  );
}
