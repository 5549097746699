import React from 'react';
const ReactDOM = require('react-dom');

// Shared icanmakeitbetter stuffs
const DateTimePicker = require('../components/common/DateTimePicker').default;

let initCount = 0;

export function init() {
  if (initCount > 0) {
    return false;
  }
  $(document).on('click', '.show_edit_form', toggleEditForm);
  $('.status_widget')
    .not('.static_status_widget')
    .statusWidget();
  lightboxImages();
  // activate tooltips
  $('.tip-pop').popover({ html: true });
  return (initCount += 1);
}

export function renderDateTimePicker(id, props = {}) {
  const el = typeof id === 'string' ? document.getElementById(id) : id;
  ReactDOM.render(React.createElement(DateTimePicker, props), el);
}

export function closePopovers(parent) {
  if (parent) {
    return $(parent)
      .find('.tip-pop')
      .popover('hide');
  }
  return $('.tip-pop').popover('hide');
}

// If we are checking if a user is authorized and the auth policy is
// missing then we default to not authorized.
//
export function checkAuthorizationPolicy(auth_policy) {
  // auth policies are conditionally included in erb so need to check defined, etc
  if (typeof window.initialState === 'undefined' || window.initialState === null) {
    return false;
  }

  const policies = window.initialState.policies;

  if (typeof policies === 'undefined' || policies === null) {
    return false;
  }

  const authorized = (typeof policies[auth_policy] === 'undefined') ? false : policies[auth_policy];

  return authorized;
}

// Feature flags are different from auth policies in that if the flag has been
// lifted (feature completely released and future recall is unlikely) then
// the feature flag wont exist.  In that case we should return true (feature is available).
//
export function checkFeatureFlag(feature_flag) {
  // feature flags are conditionally included in erb so need to check defined, etc
  if (typeof window.initialState === 'undefined' || window.initialState === null) {
    return true;
  }

  const featureFlags = window.initialState.feature_flags;

  if (typeof featureFlags === 'undefined' || featureFlags === null) {
    return true;
  }

  const available = (typeof featureFlags[feature_flag] === 'undefined') ? true : featureFlags[feature_flag];

  return available;
}

function toggleEditForm(e) {
  e.preventDefault();
  const $this = $(this);
  $($this.attr('href'))
    .toggle()
    .find('textarea')
    .last()
    .focus();

  // if this is a discussion or idea comment hide the comment content
  if ($this.hasClass('edit_comment')) {
    $this
      .closest('.answer')
      .find('.comment')
      .toggle();
    return $this
      .closest('.box-cmmt')
      .find('.comment_body')
      .toggle();
  }
}

export function lightboxImages(parent) {
  const options = {
    type: 'image',
    mainClass: 'animated fadeIn',
  };

  return require.ensure([], function() {
    require('../vendor/jquery.magnific-popup').default;
    if (parent == null) {
      return $('.image')
        .not('.pdf')
        .not('.generic')
        .magnificPopup(options);
    }
    return $(parent).magnificPopup(
      $.extend(options, { delegate: '.image:not(.pdf):not(.generic)' })
    );
  });
}

export function lightboxVideo(el) {
  const options = {
    type: 'inline',
    mainClass: 'animated fadeIn pop-vid-container',
  };
  return require.ensure([], function() {
    require('../vendor/jquery.magnific-popup').default;
    return $(el).magnificPopup(options);
  });
}

export function asyncAutocomplete(fn) {
  return require.ensure([], function() {
    require('../vendor/typeahead');
    return fn();
  });
}

export function initTooltips(parent) {
  return $(parent)
    .find('.help-popup')
    .tooltip();
}

export function refreshAt(time) {
  let timeout = Date.parse(time) - Date.now();
  const hour = 1000 * 60 * 60;
  timeout = timeout > hour ? hour : timeout;
  return ICMIB.delay(timeout, () => window.location.reload(true));
}

export function submitOnChange(form) {
  return $(form).on('change', function() {
    return $(this).submit();
  });
}

export function modalLink(selector) {
  const $link = $(selector);
  return $link.on('click', function(e) {
    e.preventDefault();
    return $.ajax({
      url: $link[0].href,
      success(data) {
        return ICMIB.common.showModal(data.title, data.body);
      },
    });
  });
}

export function showModal(title, body) {
  const $modal = $('#common_modal');
  $modal.find('.modal-ttl').text(title);
  $modal.find('.modal-body').html(body);
  return $modal.modal();
}
