import React, { Component } from 'react';
import Datetime from 'react-datetime';

const formats = {
  date: { year: 'numeric', month: 'numeric', day: 'numeric' },
  datetime: 'M/D/YYYY h:mm a',
  time: 'h:mm a',
};

export default class DateTimePicker extends Component {
  static defaultProps = {
    field_type: 'datetime',
  };

  hiddenInput;

  state = {
    open: false,
    value: this.props.value
      ? new Date(this.props.value).toLocaleDateString('en-US', { timeZone: 'UTC' })
      : '',
  };

  disablePastDate = currentDate => {
    const date = new Date();
    const yesterday = date.setDate(date.getDate() - 1);
    if (this.props.minDate) {
      return currentDate > yesterday;
      return true;
    } else {
      return true;
    }
  };

  onChange(date) {
    const event = new window.Event('change', { bubbles: true });
    this.hiddenInput && this.hiddenInput.dispatchEvent(event);
    this.setState({
      value: date,
    });
  }

  onNowClick() {
    this.setState({
      value: new Date().toLocaleDateString('en-US',  { timeZone: 'UTC' }),
    });
  }

  formatHiddenVal() {
    const { value } = this.state;
    const { format } = this.props;

    return value && typeof value !== 'string' ? value.format(format) : value;
  }

  formatPlaceholder() {
    const { field_type, format } = this.props;
    const formatStr = format && format.length ? format : formats[field_type];
    return new Date().toLocaleDateString('en-US', { timeZone: 'UTC' });
  }

  render() {
    const { className, field_type, id, name, nowButton } = this.props;
    const showDate = field_type === 'date' || field_type === 'datetime';
    const showTime = field_type === 'time' || field_type === 'datetime';
    return (
      <span>
        <input
          type="hidden"
          name={name}
          ref={node => (this.hiddenInput = node)}
          value={this.formatHiddenVal()}
        />
        <Datetime
          closeOnSelect={false}
          dateFormat={showDate}
          isValidDate={this.disablePastDate}
          timeFormat={showTime}
          inputProps={{
            className,
            id,
            placeholder: this.formatPlaceholder(),
          }}
          onChange={this.onChange.bind(this)}
          value={this.state.value}
        />
        {nowButton && <NowButton onClick={this.onNowClick.bind(this)} />}
      </span>
    );
  }
}

const NowButton = ({ onClick }) => (
  <button className="btn btn-ff-base mb0" onClick={onClick} type="button">
    Start now
  </button>
);
