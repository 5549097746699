export const findPalettesFromSchemeName = function(name, palettes) {
  const scheme = (window.ICMIB_color_schemes || []).find(s => s.name === name);

  if (scheme) {
    return scheme.palettes;
  } else if (palettes != null) {
    return palettes;
  } else if (window.ICMIB_color_schemes) {
    return window.ICMIB_color_schemes[0].palettes;
  } else {
    return [[]];
  }
};
