(function($) {
  $.statusWidget = {};
  $.statusWidget.instances = $([]);

  $.fn.statusWidget = function(options, callback) {
    var objects = $([]),
      return_data;

    this.each(function() {
      var widget_options = {
        ajax: true,
        select: null,
      };
      var settings = $.extend(widget_options, options);
      // init
      if (
        $(this).data('statusWidget') === undefined ||
        $(this).data('statusWidget') === null
      ) {
        var object = {};
        object.$this = $(this);
        object.settings = settings;

        object.list = object.$this.find('ul').first();
        object.is_open = false;
        object.currentStatus = object.$this.find('.current_status');
        if (object.settings.select) {
          object.select = $(object.settings.select);
        }

        // Toggles visibility of the menu, closes other open menus
        object.toggle = function(event) {
          event && event.preventDefault();
          if (this.is_open) {
            this.close();
          } else {
            this.closeAll();
            this.open();
          }
        };

        object.close = function() {
          this.is_open = false;
        };

        object.open = function() {
          this.is_open = true;
        };

        object.setStatus = function(status_html, classname, status_sort) {
          this.currentStatus
            .html(status_html)
            .removeClass()
            .addClass('current_status label dropdown-toggle ' + classname);
          this.currentStatus.dropdown('toggle');
          object.$this.trigger('status_widget:set', [
            status_html,
            classname,
            status_sort,
          ]);
        };

        object.closeAll = function() {
          objects.each(function(i, obj) {
            if (obj.is_open) {
              $.proxy(obj.close(), obj);
            }
          });
        };

        object.handleSuccess = function(data, textStatus, jqXHR) {
          object.setStatus(data.html, data.status, data.status_sort);
          object.alert(data);
          if (callback) callback.apply(object, [data, textStatus, jqXHR]);
        };

        object.getDialog = function() {
          if (object.dialog === undefined) {
            object.dialog = $('<div></div>');
          }
          return object.dialog;
        };

        object.alert = function(data) {
          if (data.message === undefined) return false;
          var $modal = $('#common_modal');
          $modal.find('.modal-ttl').text(data.title);
          $modal.find('.modal-body').html(data.message);
          $modal.modal();
        };

        object.changeStatus = function(event) {
          event.preventDefault();

          if (this.settings.ajax) event.preventDefault();
          var $target = $(event.target);
          if ($target.is('a')) {
            if (this.settings.ajax) {
              // show busy indicator
              this.currentStatus.addClass('busy');
              this.close();
              var thewidget = this; // trying to fix an annoying bug

              $.ajax({
                url: $target.attr('href'),
                type: $target.data('method') || 'GET',
                error: function() {
                  thewidget.currentStatus.html('An error occurred');
                },
                complete: function() {
                  thewidget.currentStatus.removeClass('busy');
                },
                success: this.handleSuccess,
              });
              return false;
            } else if (this.settings.select) {
              this.setStatus($target.html(), $target.attr('class'), null);
              this.select.val($target.html());
              this.close();
            } else {
              // no ajax, no select
            }
          }
        };

        // find toggler and attach event
        object.$toggle = object.currentStatus.bind(
          'click',
          $.proxy(object.toggle, object)
        );
        object.$this.data('statusWidget', object);

        // watch for clicks on links
        if (object.settings.ajax)
          object.list.bind('click', $.proxy(object.changeStatus, object));

        // hide the select if one is specified
        if (object.select) {
          object.select.css('visibility', 'hidden');
        }
      } else {
        object = $(this).data('statusWidget');
      }
      objects.push(object);
      $.statusWidget.instances.push(object);
    });

    if (objects.length === 1) {
      return_data = objects[0];
    } else {
      return_data = objects;
    }

    return_data.all = function(callback) {
      $.each(objects, function() {
        callback.apply(this);
      });
    };

    // watch for close clicks _checkExternalClick
    // $(document).mousedown($.statusWidget.checkOutsideClick);

    return return_data;
  };

  // $.statusWidget.checkOutsideClick = function(event){
  //   var $target = $(event.target);
  //   if (!$target.parents().addBack().is('.status_widget, .skip_widget, .survey_widget')) {
  //     $.statusWidget.closeAll();
  //   }
  // }
  $.statusWidget.closeAll = function() {
    $.statusWidget.instances.each(function(i, obj) {
      obj.close();
    });
  };
})(window.jQuery);
