export function init() {
  // Default handling of certain ajax responses
  registerAjaxCallbacks();
}

/*
    Ajax Callbacks
*/

function registerAjaxCallbacks() {
  return $.ajaxSetup({
    statusCode: {
      401: jQuerySessionModal,
      418: jQuerySessionModal,
    },
  });
}

export function initUserRegistrationValidation() {
  import(/* webpackChunkName: "validations" */ '../legacyValidations').then(
    () => ICMIB.validations.initUserRegistrationValidation()
  );
}

function jQuerySessionModal(xhr) {
  popoverSessionModal(xhr.responseJSON);
}

export function popoverSessionModal(response) {
  const $modal = $('#common_modal');
  if (response) {
    $modal.find('.modal-body').html(response.form);
    $modal.find('.modal-ttl').text(response.title);
    $modal.modal();
    initUserRegistrationValidation();
    initSocialLogin();
    $modal.width(400).css('marginLeft', '-200px');
  }
}

export function initSocialLogin() {
  if (window.janrain == null) {
    window.janrain = {};
  }
  if (window.janrain.settings == null) {
    window.janrain.settings = {};
  }

  window.janrain.settings.tokenUrl = $('meta[name="rpx_token_url"]').attr(
    'content'
  );
  window.janrain.settings.custom = true;

  const isReady = () => (window.janrain.ready = true);

  if (document.addEventListener) {
    document.addEventListener('DOMContentLoaded', isReady, false);
  } else {
    window.attachEvent('onload', isReady);
  }

  const e = document.createElement('script');
  e.type = 'text/javascript';
  e.id = 'janrainAuthWidget';

  if (document.location.protocol === 'https:') {
    e.src = 'https://rpxnow.com/js/lib/login.icanmakeitbetter.com/engage.js';
  } else {
    e.src =
      'http://widget-cdn.rpxnow.com/js/lib/login.icanmakeitbetter.com/engage.js';
  }

  const s = document.getElementsByTagName('script')[0];
  s.parentNode.insertBefore(e, s);

  $('.social_signin_box').on('click', 'button', function() {
    // if this is not a janrain button, but a normal login link
    if (this.tagName === 'A') {
      return true;
    }
    triggerFlow($(this).data('provider'));
  });

  let BACKOFF = 10;
  function triggerFlow(provider) {
    if (window.janrain) {
      window.janrain.engage.signin.triggerFlow(provider);
    } else {
      BACKOFF = BACKOFF * 2;
      window.setTimeout(() => triggerFlow(provider), BACKOFF);
    }
  }
}
