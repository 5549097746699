import React from 'react';

export default class Timestamp extends React.Component {
  static defaultProps = { format: 'datetime' };
  render() {
    const { className, format, time } = this.props;
    return time ? (
      <time className={className} dateTime={time} title={time}>
        {formatTimestamp({ timestamp: time, format })}
      </time>
    ) : null;
  }
}

export function formatTimestamp({
  timestamp,
  format,
  locale,
}) {
  if (!timestamp) return null;

  const date = new Date(timestamp);
  let theLocale = locale || window.navigator.language;

  try {
    if (date === null) {
      return null;
    } else if (format === 'date') {
      return date.toLocaleDateString(theLocale);
    } else if (format === 'time') {
      return date.toLocaleTimeString(theLocale);
    } else {
      return date.toLocaleString(theLocale);
    }
  } catch (e) {
    return date ? date.toString() : null;
  }
}
