import './vendor';
import { Mount } from '../utils/bootstrapComponents';
import raf from 'raf';

import '../icmib/jquery.status_widget';
import * as colors from '../icmib/colors';
import { substitute, t } from '../utils/translate';
import * as common from '../icmib/icmib';
import * as sessions from '../icmib/sessions';
import * as chat from '../icmib/chat';
import '../icmib/translate_placeholders.js';
import cookiePolicyNotice from '../utils/cookiePolicyNotice';

// TODO: replace w new uploader?
import * as uploader from '../icmib/attachments';

require("trix")

ICMIB.chat = chat;
ICMIB.colors = colors;
ICMIB.common = common;
ICMIB.initSocialLogin = sessions.initSocialLogin;
ICMIB.sessions = sessions;
ICMIB.substitute = substitute;
ICMIB.t = t;
ICMIB.uploader = uploader;
ICMIB.cookiePolicyNotice = cookiePolicyNotice;

ICMIB.delay = (delay, cb) => setTimeout(cb, delay);
ICMIB.hideAfter = (el, delay) => ICMIB.delay(delay, () => el.hide());
ICMIB.removeAfter = (el, delay) => ICMIB.delay(delay, () => el.remove());

common.init();
sessions.init();
uploader.init();

// RewardsTable is a little weird, has a waterfall, might be admin only?

//

let resolver = null;

function assignResolver() {
  resolver = require.context('../splits/application', true, /\.js$/);
}
assignResolver();

function bootstrapComponents() {
  assignResolver();
  Mount.init(resolver);
}
$(bootstrapComponents);

if (module.hot) {
  module.hot.accept(resolver.id, bootstrapComponents);
}

jQuery(document).ready(function(){
  jQuery('[data-toggle="popover"]').popover();
});

// disable trix uploads for now
// we can enable them once we integrate action text and active storage
function disableTrixAttachments() {
  addEventListener("trix-initialize", function(e) {
    const file_tools = document.querySelector(".trix-button-group--file-tools");
    file_tools.remove();
  })
  addEventListener("trix-file-accept", function(e) {
    e.preventDefault();
  })
}
disableTrixAttachments();
