// !(function($) {
//   // Fetch all the bootstrap jquery p[ugins in one bundle, async]
//   function loadBootstrapModules(cb) {
//     require.ensure([], () => {
//       require('../../vendor/bootstrap/bootstrap-transition');
//       require('../../vendor/bootstrap/bootstrap-modal');
//       require('../../vendor/bootstrap/bootstrap-dropdown');
//       require('../../vendor/bootstrap/bootstrap-tab');
//       require('../../vendor/bootstrap/bootstrap-tooltip');
//       require('../../vendor/bootstrap/bootstrap-popover');
//       require('../../vendor/bootstrap/bootstrap-collapse');
//       cb();
//     });
//   }

//   // Before the actual plugins are loaded, stub out the methods and have them
//   // wait until the plugins are loaded before passing the args to the full implementation
//   ['modal', 'dropdown', 'tab', 'tooltip', 'popover', 'collapse'].forEach(
//     name => {
//       $.fn[name] = function() {
//         const args = arguments;
//         loadBootstrapModules(() => this[name].apply(this, args));
//         return this;
//       };
//     }
//   );
// })(window.jQuery);

require('../../vendor/bootstrap/bootstrap-transition');
require('../../vendor/bootstrap/bootstrap-modal');
require('../../vendor/bootstrap/bootstrap-dropdown');
require('../../vendor/bootstrap/bootstrap-tab');
require('../../vendor/bootstrap/bootstrap-tooltip');
require('../../vendor/bootstrap/bootstrap-popover');
require('../../vendor/bootstrap/bootstrap-collapse');
require('../../vendor/bootstrap/bootstrap-alert');
