import { t } from '../utils/translate';

const cleanFileName = require('../components/common/uploader/cleanFileName')
  .default;

const defaults = {
  runtimes: 'html5,flash,html4',
  max_file_size: '500mb',
  filters: [
    { title: 'Image files', extensions: 'jpg,jpeg,gif,png' },
    { title: 'PDF files', extensions: 'pdf' },
  ],
  multipart: true,
  init: {
    FilesAdded(up, files) {
      return filesAddedHandler(up, files);
    },
    UploadProgress(up, file) {
      return uploadProgressHandler(up, file);
    },
    FileUploaded(up, file, data) {
      return fileUploadedHandler(up, file, data);
    },
    Error(up, error) {
      return uploaderErrorHandler(up, error);
    },
  },
};

const token = $('meta[name=csrf-token]').attr('content');

export function init() {
  // initiate the uploaders
  return $('.mlt_upl').each(function() {
    return findOrCreateUploader($(this));
  });
}

// Uploaders are attached to buttons via jquery data
export function findOrCreateUploader(button) {
  if (!button[0]) {
    return false;
  }
  if (button.data('uploader')) {
    return new Promise(function(resolve) {
      return resolve(button.data('uploader'));
    });
  }
  // return button.data('uploader')

  return new Promise(function(resolve) {
    return require.ensure(
      [],
      function() {
        require('../vendor/plupload/moxie');
        require('../vendor/plupload/plupload.dev');

        const options = {
          url: button.data('url'),
          flash_swf_url: button.data('flash-url'),
          browse_button: button[0].id,
          drop_element: button.data('drop-element'),
          jquery_button: button,
          queue_element: findQueue(button),
          file_data_name: 'file',
          idea_uploader: button.closest('.idea_form').length > 0,
          filters: button.data('filters'),
          autochange: button.data('autochange'),
          excludes: button.data('excludes'),
          container: button.data('container'),
          uploader_type: button.data('uploader-type'),
          multipart_params: {
            // s3 stuff
            key: button.data('key'),
            Filename: '${filename}', // To keep this consistent across runtimes
            acl: 'private', // 'private' is the default value
            success_action_status: '201',
            AWSAccessKeyId: button.data('aws-access-key-id'),
            policy: button.data('s3-policy'), // A Base64-encoded policy document
            signature: button.data('s3-signature'),
          }, // A signature value that authorizes the form
        };

        const uploader = new window.plupload.Uploader(
          $.extend(defaults, options)
        );

        uploader.init();

        initDropElementHanders(uploader, options['drop_element']);

        $(`#${button[0].id}`).data('uploader', uploader); //button.data('uploader', uploader)

        observeRemoveButton(uploader);

        button.removeClass('disappear');

        return resolve(uploader);
      },

      'plupload'
    );
  });
}

function findQueue($btn) {
  if ($btn.data('queue')) {
    return $(`#${$btn.data('queue')}`);
  } else {
    return $btn.siblings('.mlt_upl_q');
  }
}

//# Custom observers

function observeRemoveButton(up) {
  return up.settings.queue_element.delegate('.upl_x', 'click', e =>
    handleRemoveClick(e, up)
  );
}

//# Handlers

function initDropElementHanders(uploader, id) {
  if (id == null) {
    return false;
  }
  if (!Modernizr.draganddrop) {
    return false;
  }
  const $drop_element = $(`#${id}`);
  return $drop_element
    .on('dragenter', e => handleDropElementDragenter($drop_element, e))
    .on('dragleave', e => handleDropElementDragleave($drop_element, e))
    .on('drop', () => handleDropElementDrop($drop_element));
}

function handleDropElementDragenter($drop_element) {
  return $drop_element.addClass('dragover');
}

function handleDropElementDragleave($drop_element, e) {
  // get the location on screen of the drop element
  const rect = $drop_element[0].getBoundingClientRect();
  const x = e.originalEvent.clientX;
  const y = e.originalEvent.clientY;

  // check if the mouseEvent coordinates are outside the rect
  if (
    x > rect.left + rect.width ||
    x < rect.left ||
    y > rect.top + rect.height ||
    y < rect.top
  ) {
    return $drop_element.removeClass('dragover');
  }
}

function handleDropElementDrop($drop_element) {
  return $drop_element.removeClass('dragover');
}

// Append added file info to the nearest file queue
function filesAddedHandler(up, files) {
  // get the queue from data-attribute, or just the nearest queue
  const $btn = up.settings.jquery_button;
  $(document).trigger('attachment:uploading', [
    $btn.attr('data-temp-multi-attachment-key'),
  ]);
  const $queue = up.settings.queue_element;
  const icon = '<span class="icon icon_attachment"></span>';
  const remove_link =
    '<a href="#remove" class="upl_x"><span class="icon icon_x"></span></a>';
  const progress =
    '<div class="prg upl_prg">' +
    '<span class="prg_bar upl_prg_bar" style="width:0%;" id=""></span>' +
    '</div>';
  const processing = $btn.data('show-processing')
    ? `<div class="upl_prc disappear"><span class="icon icon_ajax progress-icon"></span> ${t(
        'processing'
      )}</div>`
    : `<div class="upl_prc disappear"><span class="icon icon_tick progress-icon c-green2"></span>${t(
        'upload_complete'
      )}</div>`;
  const leaveWarning = `<div class="upl_warning">${t(
    'please_do_not_leave_uploaded'
  )}</div>`;

  for (let file of Array.from(files)) {
    renameFile(up, file);
    const size = `<i>${window.plupload.formatSize(file.size)}</i>`;
    const html = `<div class="mlt_upl_q_i clearfix" id="mlt_upl_${
      file.id
    }" data-id="${file.id}">${remove_link} ${icon} ${
      file.name
    } ${size} ${progress} ${processing} ${leaveWarning}</div>`;
    $queue.html(html);
    if (up.settings.autochange) {
      up.settings.uploader_type = uploaderTypeFromExtension(file.name);
    }
  }

  if ($btn.data('compact')) {
    startCompactSpinner($btn);
  }
  beginLeavePageGuard($btn);
  showPageLeaveWarning($btn);
  return up.start();
}

export function uploaderTypeFromExtension(filename) {
  const extension = filename.split('.').reverse()[0];
  if (extension.match(/pdf/i)) {
    return 'pdf';
  }
  if (extension.match(/mov/i)) {
    return 'video';
  }
  if (extension.match(/mpg/i)) {
    return 'video';
  }
  if (extension.match(/mpeg/i)) {
    return 'video';
  }
  if (extension.match(/mp4/i)) {
    return 'video';
  }
  if (extension.match(/wmv/i)) {
    return 'video';
  }
  if (extension.match(/jpg/i)) {
    return 'image';
  }
  if (extension.match(/jpeg/i)) {
    return 'image';
  }
  if (extension.match(/gif/i)) {
    return 'image';
  }
  if (extension.match(/png/i)) {
    return 'image';
  }

  return 'generic';
}

function renameFile(up, file) {
  return (up.settings.multipart_params.key = cleanFileName(file.name));
}

// Update the progress bar
function uploadProgressHandler(up, file) {
  return $(`#mlt_upl_${file.id}`)
    .find('.prg_bar')
    .css('width', file.percent);
}

function fileUploadedHandler(up, file, xhr) {
  const $btn = up.settings.jquery_button;
  const $queue = up.settings.queue_element;
  const $files = $queue.find('.prg');
  $files
    .find('.prg_bar')
    .css('width', '100%')
    .addClass('upl_prg_bar_done');
  $queue.find('.upl_warning').hide();

  initProcessing(xhr, $btn);

  // Only update the preview if a survey row or choice
  if ($queue.is('.sv_c_upl_q')) {
    reloadPreview(up);
  }
  return $files.addClass('disappear');
}

function handleRemoveClick(e, up) {
  e.preventDefault();
  let $btn = $(e.target);
  if (!$btn.is('a')) {
    $btn = $btn.closest('a');
  }
  $btn.closest('div').addClass('disappear');
  removePreview(up.settings.jquery_button);

  $(document).trigger(
    up.settings.jquery_button.data('uploader-type') + '.mlt_upl_delete',
    [$btn]
  );
  $(document).trigger('mlt_upl_delete', [{ btn: $btn }]);
  $(document).trigger('attachment:delete', [
    $btn.attr('data-temp-multi-attachment-key'),
  ]);
  $.ajax({
    url: $btn[0].href,
    type: 'DELETE',
    error() {
      return $btn.closest('div').removeClass('disappear');
    },
    success() {
      reloadPreview(up);
      return $btn.closest('div').remove();
    },
  });
  return false;
}

export function disappearQueueElements($btn) {
  if ($btn == null) {
    return false;
  }
  const $items = findQueue($btn).find('div');
  $items.addClass('disappear');
  ICMIB.delay(500, () => $items.remove());
  return $btn;
}

function uploaderErrorHandler(up, error) {
  return ajaxErrorHandler(up.settings.jquery_button, error);
}

function attachmentCreatorErrorHandler($btn, error) {
  return ajaxErrorHandler($btn, error);
}

function ajaxErrorHandler($btn) {
  disappearQueueElements($btn);
  endLeavePageGuard($btn);
  hidePageLeaveWarning($btn);
  showErrorMessage($btn);
  return $(document).trigger('attachment:failed', [
    $btn.attr('data-temp-multi-attachment-key'),
  ]);
}

const error_message = t('upload_failed_message');

function makeErrorElement(msg) {
  const icon = '<span class="icon icon_warning"></span>';
  return `<div class="mlt_upl_q_i mlt_upl_q_err">${icon} ${msg}</div>`;
}

function showErrorMessage($btn, msg) {
  if (msg == null) {
    msg = error_message;
  }
  const message = makeErrorElement(msg);
  return $btn.data('uploader').settings.queue_element.html(message);
}

function handleCheckProgressSuccess(data, $btn) {
  endLeavePageGuard($btn);

  if (data.status === 'failed') {
    return ajaxErrorHandler($btn, data);
  }
  if (data.status === 'processing' || !data.status) {
    return ICMIB.delay(2000, () => checkProgress(data, $btn));
  }
  if (data.status !== 'success') {
    return ICMIB.delay(2000, () => checkProgress(data, $btn));
  }
  $btn
    .data('uploader')
    .settings.queue_element.find('.upl_prc')
    .addClass('disappear');

  if ($btn.data('compact')) {
    stopCompactSpinner($btn);
  }

  if (data.doc_type === 'image') {
    showThumb(data, $btn);
    showPreview(data, $btn);
  }
  // else if data.doc_type == 'video'
  // alert('Video uploaded')
  // TODO: show video thumb

  $(document).trigger($btn.data('uploader-type') + '.mlt_upl_success', [
    $btn,
    data,
  ]);
  $(document).trigger('mlt_upl_success', [{ btn: $btn, data }]);
  return $(document).trigger('attachment:success', [
    $btn.attr('data-temp-multi-attachment-key'),
  ]);
}

//# Some utilities

function initProcessing(xhr, $btn) {
  $btn = $(`#${$btn[0].id}`);
  $(document).trigger('attachment:processing', [
    $btn.attr('data-temp-multi-attachment-key'),
  ]);

  return $.ajax({
    url: $btn.data('create-url'),
    type: 'post',
    data: {
      location: unescape(xhr.response.match(/<Location>(.*)<\/Location>/)[1]),
      bucket: unescape(xhr.response.match(/<Bucket>(.*)<\/Bucket>/)[1]),
      key: unescape(xhr.response.match(/<Key>(.*)<\/Key>/)[1]),
      etag: unescape(xhr.response.match(/<ETag>(.*)<\/ETag>/)[1]),
      authenticity_token: token,
      uploader_type: $btn.data('uploader').settings.uploader_type,
      attachable_type: $btn.data('attachable-type'),
      attachable_id: $btn.attr('data-attachable-id'),
      embedded_class: $btn.attr('data-embedded-class'),
      'attachment[temp_multi_attachment_key]': $btn.attr(
        'data-temp-multi-attachment-key'
      ),
      'attachment[survey_question_id]': $btn.attr('data-survey-question-id'),
      'attachment[matrix_row_id]': $btn.attr('data-marix-row-id'),
      'attachment[token_id]': $btn.attr('data-token-id'),
    },
    beforeSend() {
      // Add "Processing..." text
      return $btn
        .data('uploader')
        .settings.queue_element.find('.upl_prc')
        .removeClass('disappear');
    },
    complete() {},
    success(data) {
      updateRemoveButton(data, $btn);
      return ICMIB.delay(1000, () => checkProgress(data, $btn));
    },
    error(xhr) {
      return attachmentCreatorErrorHandler($btn, xhr);
    },
  });
}

function checkProgress(attachment, $btn) {
  return $.ajax({
    url: `${$btn.data('progress-url')}?id=${attachment._id}`,
    data: {
      uploader_type: $btn.data('uploader').settings.uploader_type,
    },
    beforeSend() {},
    complete() {},
    success(data) {
      return handleCheckProgressSuccess(data, $btn);
    },
    error(xhr) {
      return attachmentCreatorErrorHandler($btn, xhr);
    },
  });
}

function updateRemoveButton(attachment, $btn) {
  return $btn
    .data('uploader')
    .settings.queue_element.find('.upl_x')
    .attr('href', attachment.remove_path);
}

function showThumb(data, $btn) {
  // Shows the tiny thumbnail next to the progress bar
  if ($btn.data('uploader').settings.queue_element.is('.sv_upl_q')) {
    ICMIB.delay(1500, () => reloadPreview($btn.data('uploader')));
  }
  $btn.data('thumb-retries', 1);
  return renderThumbImage(data, $btn);
}

function showPreview(data, $btn) {
  // Shows a larger image preview if data attributes are there
  const id = $btn.data('preview-id');
  const thumb = $btn.data('preview-thumb');
  if (!id || !thumb) {
    return false;
  }

  const old_img = $(`#${id} img`);
  const img = old_img[0] ? old_img.clone() : $('<img />');
  img.attr({ src: eval(`data.${thumb}_url`) });
  img[0].onerror = () => handlePreviewError(img, id, thumb);

  return ICMIB.delay(100, function() {
    img.appendTo($(`#${id}`).html(''));
    return old_img.remove();
  });
}

function removePreview($btn) {
  return $(`#${$btn.data('preview-id')} img`).remove();
}

function renderThumbImage(data, $btn) {
  if (!data.tiny_square_url) {
    return false;
  }
  const $img = $('<img />', {
    src: data.tiny_square_url,
    'data-btn-id': $btn[0].id,
  });
  $img[0].onerror = handleThumbError;
  return $btn
    .data('uploader')
    .settings.queue_element.find('.mlt_upl_q_i')
    .prepend($img);
}

function handleThumbError() {
  const $img = $(this).remove();
  const attachment = { tiny_square_url: this.src };
  const $btn = $(`#${$img.data('btn-id')}`);
  $btn.data('thumb-retries', $btn.data('thumb-retries') * 2);
  return ICMIB.delay(1000 * $btn.data('thumb-retries'), () =>
    renderThumbImage(attachment, $btn)
  );
}

function handlePreviewError(img) {
  let retry_count = img.data('preview-retries');
  retry_count = retry_count ? retry_count + 1 : 1;
  img.data('preview-retries', retry_count);
  const src = img.attr('src');
  return ICMIB.delay(500 * retry_count, () =>
    img.attr('src', src.split('?')[0] + '?' + retry_count)
  );
}

function reloadPreview(up) {
  // reloadPreview is for survey question previews
  if (!up.settings.queue_element.is('.sv_upl_q')) {
    return false;
  }

  // If this is a survey question
  if (up.settings.queue_element.is('.sv_q_upl_q')) {
    up.settings.jquery_button.closest('form').trigger('submit');
  }

  // If a survey row or choice
  if (up.settings.queue_element.is('.sv_c_upl_q')) {
    return up.settings.jquery_button
      .closest('li')
      .find('input[type=text]')
      .first()
      .trigger('change');
  }
}

function beginLeavePageGuard($btn) {
  // create a warning if they try to leave the page during upload
  return $(window).on(`beforeunload.${$btn.attr('id')}`, () =>
    t('confirm_interrupt_upload')
  );
}

function endLeavePageGuard($btn) {
  return $(window).off(`beforeunload.${$btn.attr('id')}`);
}

function showPageLeaveWarning() {}

function hidePageLeaveWarning() {}

export function updateAttachableID($btn, id) {
  return $btn
    .data('attachable-id', id)
    .attr('data-attachable-id', id)
    .data('temp-multi-attachment-key', id)
    .attr('data-temp-multi-attachment-key', id);
}

function startCompactSpinner($btn) {
  $btn.data('original-html', $btn.html());
  return $btn.html('<span class="icon icon_ajax"></span>');
}

function stopCompactSpinner($btn) {
  return $btn.html($btn.data('original-html'));
}
