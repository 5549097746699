import React from 'react';
import ReactDOM from 'react-dom';
import exposeComponentOnData from './exposeComponentOnData';
import raf from 'raf';

export const componentDataAttr = 'data-react-component-name';

export function bootstrapComponent(i, node, overrideProps = {}) {
  if (!node) return;
  const path = './' + node.getAttribute(componentDataAttr);
  const resolution = Mount.resolveAll(path);
  if (resolution) {
    const props = {
      ...getPropsFromAttributes(node),
      ...overrideProps,
    };
    renderComponent(resolution.default, props, node);
  }
}

ICMIB.bootstrapComponent = bootstrapComponent;

export function getPropsFromAttributes(node) {
  const propsString = $(node).attr('data-props');
  return propsString ? JSON.parse(propsString) : {};
}

function isClassComponent(component) {
  return !!component.prototype.render;
}

export function renderComponent(componentClass, props, node) {
  let ref;
  let newProps = isClassComponent(componentClass)
    ? { ...props, ref: node => (ref = node) }
    : props;
  const component = React.createElement(componentClass, newProps);
  ReactDOM.render(component, node);
  exposeComponentOnData(node, ref);
  return ref;
}

// Store all resolvers so we can use them later if necessary
if (window.ICMIB.resolvers === undefined) {
  window.ICMIB.resolvers = [];
}

export class Mount {
  static init(r) {
    raf(() => {
      if (r) {
        window.ICMIB.resolvers.push(r);
      }
      const mounts = Array.from(
        document.querySelectorAll(`[${componentDataAttr}]`)
      );
      mounts.forEach(mount => {
        const path = './' + mount.getAttribute(componentDataAttr);
        const resolution = r ? Mount.resolve(path, r) : Mount.resolveAll(path);
        if (resolution) {
          const props = getPropsFromAttributes(mount);
          renderComponent(resolution.default, props, mount);
        }
      });
    });
  }

  static resolve(path, resolver) {
    const tests = [
      path + '.jsx',
      path + '.js',
      path + '/index.jsx',
      path + '/index.js',
    ];
    const keys = resolver.keys();
    const match = tests.find(test => keys.includes(test));
    return match && resolver(match);
  }

  // Will look through all resolvers
  static resolveAll(path) {
    const tests = [
      path + '.jsx',
      path + '.js',
      path + '/index.jsx',
      path + '/index.js',
    ];
    let allKeys = [];
    const resolver = window.ICMIB.resolvers.find(r => {
      const keys = r.keys();
      allKeys.concat(keys);
      return tests.find(test => keys.includes(test));
    });
    if (resolver) {
      return Mount.resolve(path, resolver);
    } else {
      return false;
    }
  }
}
