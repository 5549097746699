import React from 'react';

export default function Fallback({ __placeholder_html__ }) {
  return __placeholder_html__ ? (
    <div dangerouslySetInnerHTML={{ __html: __placeholder_html__ }} />
  ) : (
    <div className="d-flex jc-center align-center">
      <div>Loading...</div>
    </div>
  );
}
