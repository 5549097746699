export function countdown(countdownId, timestampId, chatURL, chatStartAt) {
  return require.ensure(
    [],
    function() {
      if (window.moment == null) {
        moment = window.moment = require('moment');
      }
      // Timestamp with abbreviated timezone
      moment = window.moment = require('moment-timezone');
      const timestamp = moment(chatStartAt).format('MMMM Do YYYY, h:mm a');
      const abbreviatedTimeZone = moment(chatStartAt).tz(moment.tz.guess()).zoneAbbr();
      const selectedElement = document.querySelector(timestampId);
      selectedElement.innerHTML = `${timestamp} ${abbreviatedTimeZone}`

      // Countdown timer
      window.moment.countdown = require('moment-countdown');
      const $countdown = $(countdownId);
      var end = (end = moment($('#countdown').text()));

      var update = function() {
        const now = moment();
        if (now.isAfter(end)) {
          return window.location.replace(chatURL);
        }
        const cd = moment(now).countdown(end);
        $countdown.text(cd.toString());
        return setTimeout(() => window.requestAnimationFrame(update), 100);
      };

      return update();
    },
    'countdown'
  );
}
