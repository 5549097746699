import * as cookies from './cookies';

export default function cookiePolicyNotice() {
  const $ck_info = $('#ck_info');
  const dontShowCookieInfo = () => $ck_info.remove();
  const hideCookieInfo = e => {
    e && e.preventDefault();
    $ck_info.removeClass('o-100').css('bottom', '-9em');
    setTimeout($ck_info.remove, 300);
  };
  const showCookieInfo = () => {
    cookies.set('ICMIB.dismiss_ck_info', true, new Date(2022, 1, 1));
    setTimeout(() => {
      $ck_info
        .addClass('o-100')
        .css('bottom', 0)
        .on('click', '.dismiss', hideCookieInfo);
      setTimeout(hideCookieInfo, 30000);
    }, 2000);
  };

  cookies.get('ICMIB.dismiss_ck_info') === 'true'
    ? dontShowCookieInfo()
    : showCookieInfo();
}
