import React from 'react';

const Loading = ({ className, style } = {}) => ({
  error,
  timedOut,
  pastDelay,
}) => {
  if (error || timedOut) {
    return (
      <div title="This feature failed to load. We've been notified and will fix it shortly.">
        This feature failed to load.
      </div>
    );
  } else if (pastDelay) {
    return (
      <div className={className} style={style}>
        <div>Loading...</div>
      </div>
    );
  } else {
    return <div className={className} style={style} />;
  }
};

export default Loading;
