export function t(key, subs) {
  try {
    if (!ICMIB || !ICMIB.dictionary) {
      return key;
    }
    if (!ICMIB.dictionary[key]) {
      // istanbul ignore if
      if (!(process && process.env && process.env.NODE_ENV === 'test')) {
        console && console.warn(`Missing translation: ${key}`);
      }
      return key;
    }
    return subs
      ? substitute(ICMIB.dictionary[key], subs)
      : ICMIB.dictionary[key];
  } catch (exception) {
    // istanbul ignore next
    return key;
  }
}

export function substitute(string, subs) {
  const segs = string.split('{{}}');
  const combined = segs.reduce((result, seg, idx) => {
    return result.concat(seg, subs[idx]);
  }, []);
  return combined.join('');
}

export function tcap(key, subs) {
  const s = t(key, subs) + '';
  return capitalize(s);
}

export function capitalize(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
